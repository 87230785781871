 <template>
  <b-card title="Add Product">
    <b-alert v-if="error" variant="danger" show>
      <div class="alert-body">
        <span>{{ error }}</span>
      </div>
    </b-alert>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Product Name"
            label-for="h-product-title"
            label-cols-md="4"
          >
            <b-form-input
              id="h-product-template-url"
              type="text"
              v-model="product.name"
              placeholder="Product Name / Title"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Slug"
            label-for="h-product-slug"
            label-cols-md="4"
          >
            <b-form-input
              id="h-product-template-url"
              type="text"
              disabled
              v-model="slug"
              placeholder="Product Slug"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Description"
            label-for="h-video-url"
            label-cols-md="4"
          >
            <div id="quill-toolbar" class="d-flex border-bottom-0">
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
            </div>
            <div class="quill__container">
              <quill-editor
                id="quil-content"
                v-model="product.description"
                :options="editorOption"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Product Version"
            label-for="h-product-version"
            label-cols-md="4"
          >
            <b-form-input
              id="h-product-version"
              type="number"
              v-model="product.version"
              placeholder="Product Version"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Product Price"
            label-for="h-product-price"
            label-cols-md="4"
          >
            <b-form-input
              id="h-product-template-url"
              type="number"
              v-model="product.price"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Product Category"
            label-for="h-product-category"
            label-cols-md="4"
          >
            <v-select
              v-model="product.categories"
              placeholder="Product Categories"
              label="title"
              :options="categories"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Other Category"
            label-for="h-product-other-category"
            label-cols-md="4"
          >
            <v-select
              v-model="product.brand"
              placeholder="Other Categories"
              label="title"
              :options="brands"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Tier"
            label-for="h-product-tier"
            label-cols-md="4"
          >
            <v-select
              v-model="product.tier"
              placeholder="Tier"
              label="title"
              :options="productTier"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Status"
            label-for="h-product-status"
            label-cols-md="4"
          >
            <v-select
              v-model="product.status"
              placeholder="Status"
              label="title"
              :options="productStatus"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Product Pic URL"
            label-for="h-product-pic-url"
            label-cols-md="4"
          >
            <b-form-input
              id="h-product-image-url"
              v-model="product.image_url"
              type="text"
              placeholder="Product Pic URL"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Document URL 1"
            label-for="h-product-document-url-1"
            label-cols-md="4"
          >
            <b-form-input
              id="h-product-document-url-1"
              v-model="product.document_url_1"
              type="text"
              placeholder="Document URL 1"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Document URL 2"
            label-for="h-product-product-document-url-2"
            label-cols-md="4"
          >
            <b-form-input
              id="h-product-product-document-url-2"
              v-model="product.document_url_2"
              type="text"
              placeholder="Document URL 2"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Template URL"
            label-for="h-product-template-url"
            label-cols-md="4"
          >
            <b-form-input
              id="h-product-template-url"
              type="text"
              v-model="product.template_url"
              placeholder="Template URL"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Video URL"
            label-for="h-video-url"
            label-cols-md="4"
          >
            <b-form-input
              id="h-video-url"
              v-model="product.video_url"
              type="text"
              placeholder="Video URL"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4" class="mt-1">
          <b-button
            @click="onSubmit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            class="mr-1"
          >
            <div v-if="isLoading" class="text-center loader">Saving...</div>
            <div v-else>Save Product</div>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useProduct } from "../useProduct";
const { addProductToDatabase } = useProduct();
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BAlert,
    BButton,
    BFormTextarea,

    // third party
    quillEditor,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: { title: "Square" },
      categories: ["Terraform", "Serverless", "CloudFormation"],
      brands: ["Data", "Acillary", "Modernization", "Custom Architecture"],
      productStatus: ["Limited", "Available", "Out Of Stock"],
      productTier: ["Single", "Multiple", "Extended"],
      editorOption: {
        modules: {
          toolbar: "#quill-toolbar",
        },
        placeholder: "Product Description",
      },
      product: {
        business_id: "",
        product_id: "",
        version: "",
        tier: "",
        status: "",
        slug: "",
        brand: "",
        video_url: "",
        name: "",
        template_url: "",
        categories: "",
        image_url: "",
        description: "",
        price: 0,
        document_url_1: "",
        document_url_2: "",
      },
      isLoading: false,
      error: null,
    };
  },
  computed: {
    slug: function () {
      return this.product.name.replace(/\s/g, "-").toLowerCase();
    },
  },
  // methods
  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;
        // add product to database
        this.product.slug = this.slug;
        await addProductToDatabase(this.product);
        // show toast
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Product added`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully added a new product.`,
          },
        });
        // reset product
        this.product = {
          business_id: "",
          product_id: "",
          version: "",
          tier: "",
          status: "",
          slug: "",
          brand: "",
          video_url: "",
          name: "",
          template_url: "",
          categories: "",
          image_url: "",
          description: "",
          price: 0,
          document_url_1: "",
          document_url_2: "",
        };
        this.isLoading = false;
      } catch (error) {
        this.error = error.message;
        this.isLoading = false;
      }
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.loader {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
 
 <style lang="scss" scoped>
.quill__container {
  min-height: 80px;
  border-bottom: 0.1rem solid #dbdbdb;
  border-left: 0.1rem solid #dbdbdb;
  border-right: 0.1rem solid #dbdbdb;
  border-bottom-left-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}
form ::v-deep {
  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }
}
</style>